<template>
  <modal-wrapper
    :id="id"
    hide-footer
    size="lg"
  >
    <template #title>
      {{modalTitle}}
    </template>
    <template #body>
      <user-cdv-advanced-wizard
        :request-type="requestType"
        :request-product="requestProduct"
        :product-description="productDescription"
        :entity-type="entityType"
        :modal-id="id"
        :has-subject="hasSubjectDataSet"
        :profile-entity="profileEntity"
      />
    </template>
  </modal-wrapper>
</template>

<script>
import { isNotEmpty } from '@/utils/validators';

const UserCdvAdvancedWizard = () => import('../wizards/UserCdvAdvancedWizard.vue');
const ModalWrapper = () => import('@/components/helpers/ModalWrapper.vue');

export default {
  name: 'CdvWizardModal',
  components: { UserCdvAdvancedWizard, ModalWrapper },
  props: {
    id: String,
    requestType: String,
    requestProduct: String,
    productDescription: String,
    entityType: String,
    profileEntity: Object,
  },
  computed: {
    modalTitle() {
      return 'Acquisto Report CdV';
    },
    hasSubjectDataSet() {
      return isNotEmpty(this.profileEntity);
    },
  },
  methods: {},
};
</script>

<style scoped>

</style>
